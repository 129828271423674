import * as React from "react";
import LazyLoad from "react-lazyload";
import {
  MobileView,
  MobileOnlyView,
  BrowserView,
  TabletView,
} from "react-device-detect";
import { Helmet } from "react-helmet";
import Layout from "../components/layout";
import Cta from "../components/cta";
import Companies from "../components/companies";
import CDiagram from "../components/cdiagram";
import CDiagramMobile from "../components/cdiagrammobile";
import { StaticImage } from "gatsby-plugin-image";
import insight from "../contents/slider-insight.json";
import InsightSlider from "../components/insightslider";
import IndustryTab from "../components/industrytab";
import IndustryAccordion from "../components/industryaccordion";
import SolutionsTab from "../components/solutionstab";
import SolutionsAccordion from "../components/solutionsaccordion";
import * as Styles from "../styles/index.module.css";
import "../styles/signal.css";

const IndexPage = () => {
  // const breakpoints = useBreakpoint();
  return (
    <Layout pageTitle="Home Page">
      {/* MetaTag */}
      <Helmet
        htmlAttributes={{
          lang: "en",
        }}
      >
        <title>
          #1 Integrated Vehicle Tracking and Fleet Management Malaysia
        </title>
        <meta
          name="description"
          content="Join 3,600+ companies across South East Asia that have implemented KATSANA solutions to achieve real-time operational visibility for the modern age."
        />
        <meta
          name="robots"
          content="index, follow, max-snippet:-1, max-image-preview:large, max-video-preview:-1"
        />
        <link rel="canonical" href="https://www.katsana.com/" />

        {/* Open Graph */}
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://www.katsana.com" />
        <meta
          property="og:title"
          content="#1 Integrated Vehicle Tracking and Fleet Management Malaysia"
        />
        <meta
          property="og:description"
          content="Join 3,600+ companies across Malaysia & the region that have
          implemented KATSANA Integrated Fleet Solutions achieve real-time
          operational visibility for the modern age."
        />
        <meta
          property="og:image"
          content="https://assets.katsana.com/og-images/twt_ogimage_large.jpg"
        />
        <meta
          property="og:image:alt"
          content="KATSANA Fleet Management & Industrial IoT Sensors Expert"
        />
        <meta property="og:image:width" content="1280" />
        <meta property="og:image:height" content="640" />

        {/* Twitter Card */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:site" content="@katsanagps" />
        <meta name="twitter:creator" content="@katsanagps" />

        <meta
          name="twitter:image"
          content="https://assets.katsana.com/og-images/twt_ogimage_large.jpg"
        />
      </Helmet>
      {/* Hero Area */}
      <div
        className={
          Styles.tilt +
          " bg-gradient-to-br from-[hsla(211,55%,22%,1)] via-[hsla(211,45%,16%,1)] to-[hsla(211,45%,16%,1)] pt-20 md:pt-24 xl:pt-48 md:pb-16 after:h-[2%] after:bg-gradient-to-b after:from-[hsla(210,75%,22%,1)] after:to-[hsla(210,75%,22%,1)] "
        }
      >
        {/* Set Max Width */}
        <div className="relative mx-auto max-w-primary ">
          <div className="flex flex-col xl:flex-row-reverse xl:mt-16 xl:justify-between">
            {/* Hero Image */}
            <BrowserView>
              <LazyLoad>
              <div className="xl:max-w-[60vw] xl:w-full mt-14 xl:mt-0">
                <div
                  className="relative lg:-right-40 xl:-top-10 md:m-10 lg:m-0 xl:!absolute"
                  data-sal="slide-up"
                  data-sal-duration="500"
                  data-sal-easing="ease-in"
                >
                  <StaticImage
                    alt="Katsana IoT Sensors Expert"
                    src="../images/hero.png"
                    quality={65}
                    objectFit="contain"
                  ></StaticImage>

                  <div className="signal-wrapper">
                    {/* Blue Signal  */}
                    <div className="signals left-[25.3%] top-[20.8%]">
                      <span className="signal s1"></span>
                      <span className="signal s2"></span>
                      <span className="signal s3"></span>
                      <span className="signal s4"></span>
                    </div>
                    <div className="signals right-[55.8%] top-[1.8%]">
                      <span className="signal s1"></span>
                      <span className="signal s2"></span>
                      <span className="signal s3"></span>
                      <span className="signal s4"></span>
                    </div>
                    <div className="signals right-[38.4%] top-[33.7%]">
                      <span className="signal s1"></span>
                      <span className="signal s2"></span>
                      <span className="signal s3"></span>
                      <span className="signal s4"></span>
                    </div>
                    <div className="signals right-[19.7%] top-[11%]">
                      <span className="signal s1"></span>
                      <span className="signal s2"></span>
                      <span className="signal s3"></span>
                      <span className="signal s4"></span>
                    </div>
                    {/* Pink Signal */}
                    <div className="signals left-[2.8%] bottom-[54.8%] ">
                      <span className="!border-pink-500 signal delay s1"></span>
                      <span className="!border-pink-500 signal delay s2"></span>
                      <span className="!border-pink-500 signal delay s3"></span>
                      <span className="!border-pink-500 signal delay s4"></span>
                    </div>

                    <div className="signals right-[51%] bottom-[45%]">
                      <span className="!border-pink-500 signal delay s1"></span>
                      <span className="!border-pink-500 signal delay s2"></span>
                      <span className="!border-pink-500 signal delay s3"></span>
                      <span className="!border-pink-500 signal delay s4"></span>
                    </div>
                  </div>
                </div>

                <div className="absolute inset-0 bubble-wrapper transform scale-50 md:scale-75 lg:scale-100">
                  {/* Bubble 1 */}
                  <div
                    className={`${Styles.bubble1} absolute top-0 right-[12%]`}
                    data-sal="slide-up"
                    data-sal-duration="500"
                    data-sal-easing="ease-in"
                  >
                    <StaticImage
                      alt="Katsana IoT Sensors Expert"
                      src="../../static/images/graphics/hero-fleet-management.png"
                      quality={65}
                      objectFit="contain"
                    ></StaticImage>
                  </div>
                  {/* Bubble 2 */}
                  <div
                    className={`${Styles.bubble1} absolute top-[7%] right-[30%]`}
                    data-sal="slide-up"
                    data-sal-duration="500"
                    data-sal-easing="ease-in"
                  >
                    <StaticImage
                      alt="Katsana IoT Sensors Expert"
                      src="../../static/images/graphics/hero-vehicle-tracking.png"
                      quality={65}
                      objectFit="contain"
                    ></StaticImage>
                  </div>
                </div>
              </div>
              </LazyLoad>
            </BrowserView>
            <MobileOnlyView>
              <LazyLoad>
              <div className="xl:max-w-[60vw] xl:w-full mt-14 xl:mt-0 relative">
                <div
                  className="relative"
                  data-sal="slide-up"
                  data-sal-duration="500"
                  data-sal-easing="ease-in"
                >
                  <StaticImage
                    alt="Katsana IoT Sensors Expert"
                    src="../images/hero.png"
                    quality={65}
                    objectFit="contain"
                  ></StaticImage>

                  <div className="signal-wrapper">
                    {/* Blue Signal  */}
                    <div className="signals left-[25.3%] top-[20.8%]">
                      <span className="signal s1"></span>
                      <span className="signal s2"></span>
                      <span className="signal s3"></span>
                      <span className="signal s4"></span>
                    </div>
                    <div className="signals right-[55.8%] top-[1.8%]">
                      <span className="signal s1"></span>
                      <span className="signal s2"></span>
                      <span className="signal s3"></span>
                      <span className="signal s4"></span>
                    </div>
                    <div className="signals right-[38.4%] top-[33.7%]">
                      <span className="signal s1"></span>
                      <span className="signal s2"></span>
                      <span className="signal s3"></span>
                      <span className="signal s4"></span>
                    </div>
                    <div className="signals right-[19.7%] top-[11%]">
                      <span className="signal s1"></span>
                      <span className="signal s2"></span>
                      <span className="signal s3"></span>
                      <span className="signal s4"></span>
                    </div>
                    {/* Pink Signal */}
                    <div className="signals left-[2.8%] bottom-[54.8%] ">
                      <span className="!border-pink-500 signal delay s1"></span>
                      <span className="!border-pink-500 signal delay s2"></span>
                      <span className="!border-pink-500 signal delay s3"></span>
                      <span className="!border-pink-500 signal delay s4"></span>
                    </div>

                    <div className="signals right-[51%] bottom-[45%]">
                      <span className="!border-pink-500 signal delay s1"></span>
                      <span className="!border-pink-500 signal delay s2"></span>
                      <span className="!border-pink-500 signal delay s3"></span>
                      <span className="!border-pink-500 signal delay s4"></span>
                    </div>
                  </div>
                </div>

                <div className="absolute inset-0 bubble-wrapper top-[10%] md:top-0">
                  {/* Bubble 1 */}
                  <div
                    className="absolute top-0 left-[55%] max-w-[140px]"
                    data-sal="slide-up"
                    data-sal-duration="500"
                    data-sal-easing="ease-in"
                  >
                    <StaticImage
                      alt="Katsana IoT Sensors Expert"
                      src="../../static/images/graphics/hero-fleet-management.png"
                      quality={65}
                      objectFit="contain"
                    ></StaticImage>
                  </div>
                  {/* Bubble 2 */}
                  <div
                    className="absolute top-[10%] left-[20%] max-w-[140px]"
                    data-sal="slide-up"
                    data-sal-duration="500"
                    data-sal-easing="ease-in"
                  >
                    <StaticImage
                      alt="Katsana IoT Sensors Expert"
                      src="../../static/images/graphics/hero-vehicle-tracking.png"
                      quality={65}
                      objectFit="contain"
                    ></StaticImage>
                  </div>
                </div>
              </div>
              </LazyLoad>
            </MobileOnlyView>
            <TabletView>
              <LazyLoad>
              <div className="xl:max-w-[60vw] xl:w-full mt-24">
                <div
                  className="relative"
                  data-sal="slide-up"
                  data-sal-duration="500"
                  data-sal-easing="ease-in"
                >
                  <StaticImage
                    alt="Katsana IoT Sensors Expert"
                    src="../images/hero.png"
                    quality={65}
                    objectFit="contain"
                  ></StaticImage>

                  <div className="signal-wrapper">
                    {/* Blue Signal  */}
                    <div className="signals left-[25.3%] top-[20.8%]">
                      <span className="signal s1"></span>
                      <span className="signal s2"></span>
                      <span className="signal s3"></span>
                      <span className="signal s4"></span>
                    </div>
                    <div className="signals right-[55.8%] top-[1.8%]">
                      <span className="signal s1"></span>
                      <span className="signal s2"></span>
                      <span className="signal s3"></span>
                      <span className="signal s4"></span>
                    </div>
                    <div className="signals right-[38.4%] top-[33.7%]">
                      <span className="signal s1"></span>
                      <span className="signal s2"></span>
                      <span className="signal s3"></span>
                      <span className="signal s4"></span>
                    </div>
                    <div className="signals right-[19.7%] top-[11%]">
                      <span className="signal s1"></span>
                      <span className="signal s2"></span>
                      <span className="signal s3"></span>
                      <span className="signal s4"></span>
                    </div>
                    {/* Pink Signal */}
                    <div className="signals left-[2.8%] bottom-[54.8%] ">
                      <span className="!border-pink-500 signal delay s1"></span>
                      <span className="!border-pink-500 signal delay s2"></span>
                      <span className="!border-pink-500 signal delay s3"></span>
                      <span className="!border-pink-500 signal delay s4"></span>
                    </div>

                    <div className="signals right-[51%] bottom-[45%]">
                      <span className="!border-pink-500 signal delay s1"></span>
                      <span className="!border-pink-500 signal delay s2"></span>
                      <span className="!border-pink-500 signal delay s3"></span>
                      <span className="!border-pink-500 signal delay s4"></span>
                    </div>
                  </div>
                </div>

                <div className="absolute inset-0">
                  <div
                    className="absolute top-[2%] left-[50%]"
                    data-sal="slide-up"
                    data-sal-duration="500"
                    data-sal-easing="ease-in"
                  >
                    <StaticImage
                      alt="Katsana IoT Sensors Expert"
                      src="../../static/images/graphics/hero-fleet-management.png"
                      quality={65}
                      objectFit="contain"
                    ></StaticImage>
                  </div>
                  {/* Bubble 2 */}
                  <div
                    className="absolute top-[5%] left-[20%] lg:left-[25%]"
                    data-sal="slide-up"
                    data-sal-duration="500"
                    data-sal-easing="ease-in"
                  >
                    <StaticImage
                      alt="Katsana IoT Sensors Expert"
                      src="../../static/images/graphics/hero-vehicle-tracking.png"
                      quality={65}
                      objectFit="contain"
                    ></StaticImage>
                  </div>
                </div>
              </div>
              </LazyLoad>
            </TabletView>
            {/* Hero Text & CTA */}
            <div className="px-8 py-4 lg:w-full xl:max-w-[730px] z-10">
              <h1 className="text-4xl font-semibold text-white md:text-6xl ">
                #1 Integrated Vehicle Tracking and Fleet Management Malaysia
              </h1>
              <p className="mt-4 text-sm leading-6 md:mt-8 md:leading-8 md:text-xl text-bluegray md:max-w-lg">
                Join 3,600+ companies across Malaysia & the region that have
                implemented KATSANA Integrated Fleet Solutions achieve real-time
                operational visibility for the modern age.
              </p>
              <div className="flex flex-col justify-start mt-8 md:items-center md:flex-row md:mt-20 ">
                <a
                  href="https://apps.katsana.com/get-quotation/"
                  className="self-start px-5 py-2 font-semibold text-white rounded-full md:px-6 md:py-3 md:text-base leading-0 bg-primary"
                >
                  Request Consultation
                </a>
                <a
                  href="https://api.whatsapp.com/send?phone=60193960127&text=Welcome%20to%20KATSANA!%0AThe%20No.1%20Integrated%20Vehicle%20Tracking%20and%20Fleet%20Management%20Malaysia.%0A%0ADrop%20us%20your%20contact%20details%20and%20our%20Katsana%20consultants%20will%20get%20in%20touch%20with%20you%20soonest.%0A%0AName%3A%0ACompany%20Name%3A%0AEmail%3A"
                  className="mt-4 text-xs font-semibold md:text-base text-bluegray md:ml-4 md:mt-0"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  or message us
                  <svg
                    role="img"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                    className="inline w-6 h-6 rounded-full bg-[#25D366] ml-2 "
                  >
                    <title>WhatsApp</title>
                    <path
                      d="M17.472 14.382c-.297-.149-1.758-.867-2.03-.967-.273-.099-.471-.148-.67.15-.197.297-.767.966-.94 1.164-.173.199-.347.223-.644.075-.297-.15-1.255-.463-2.39-1.475-.883-.788-1.48-1.761-1.653-2.059-.173-.297-.018-.458.13-.606.134-.133.298-.347.446-.52.149-.174.198-.298.298-.497.099-.198.05-.371-.025-.52-.075-.149-.669-1.612-.916-2.207-.242-.579-.487-.5-.669-.51-.173-.008-.371-.01-.57-.01-.198 0-.52.074-.792.372-.272.297-1.04 1.016-1.04 2.479 0 1.462 1.065 2.875 1.213 3.074.149.198 2.096 3.2 5.077 4.487.709.306 1.262.489 1.694.625.712.227 1.36.195 1.871.118.571-.085 1.758-.719 2.006-1.413.248-.694.248-1.289.173-1.413-.074-.124-.272-.198-.57-.347m-5.421 7.403h-.004a9.87 9.87 0 01-5.031-1.378l-.361-.214-3.741.982.998-3.648-.235-.374a9.86 9.86 0 01-1.51-5.26c.001-5.45 4.436-9.884 9.888-9.884 2.64 0 5.122 1.03 6.988 2.898a9.825 9.825 0 012.893 6.994c-.003 5.45-4.437 9.884-9.885 9.884m8.413-18.297A11.815 11.815 0 0012.05 0C5.495 0 .16 5.335.157 11.892c0 2.096.547 4.142 1.588 5.945L.057 24l6.305-1.654a11.882 11.882 0 005.683 1.448h.005c6.554 0 11.89-5.335 11.893-11.893a11.821 11.821 0 00-3.48-8.413Z"
                      fill="#ffffff"
                    />
                  </svg>
                </a>
              </div>
            </div>
          </div>

          {/* Companies / Client */}

          <LazyLoad>
            <div className="pt-16 md:pt-32">
              <Companies />
            </div>
          </LazyLoad>

          {/* Proven & Impactful */}
          <div className="max-w-5xl p-8 pb-16 mx-auto">
            <h3 className="max-w-3xl mx-auto mt-12 text-2xl font-semibold text-white md:mt-0 md:text-center md:text-4xl">
            The Operating System for Fleet Operators™
            </h3>
            <p className="max-w-2xl mx-auto mt-4 mb-8 text-xs leading-6 md:text-center md:text-lg text-bluegray md:leading-8">
              Leverage on KATSANA’s deep solution engineering and operational
              experience to bring you proven and impactful digital
              transformation.
              <br />
              We guide you through every step of the way.
            </p>

            <MobileView>
              <LazyLoad>
                <SolutionsAccordion />
              </LazyLoad>
            </MobileView>

            <BrowserView>
              <LazyLoad>
                <SolutionsTab />
              </LazyLoad>
            </BrowserView>
          </div>
        </div>
      </div>

      {/* On The Cloud */}
      <div
        className={
          Styles.tilt +
          " bg-gradient-to-b from-[hsla(210,75%,22%,1)] to-[hsla(219,78%,41%,1)] mt-[-1px] after:h-[2%] after:bg-[hsla(202,28%,77%,1)] "
        }
      >
        <div className={Styles.cloudbg + " p-8 pb-16 md:pb-32 md:pt-12"}>
          <div className="max-w-6xl mx-auto">
            <h3 className="text-2xl font-semibold text-white md:text-center md:text-4xl">
              Real-time Fleet & Industrial Operational Visibility
            </h3>
            <p className="my-4 text-sm leading-6 md:text-center md:leading-8 md:text-lg text-bluegray md:max-w-2xl md:mx-auto">
              KATSANA makes enterprise operations more efficient by connecting
              legacy sensors to the cloud, making data available in real-time
              for you to make impactful and data-informed decisions.
            </p>
            <MobileView>
              <LazyLoad>
                <CDiagramMobile />
              </LazyLoad>
            </MobileView>
            <BrowserView>
              <LazyLoad>
                <CDiagram />
              </LazyLoad>
            </BrowserView>
          </div>
        </div>
      </div>
      {/* Industry-Driven Solutions */}
      <div
        className={
          " bg-gradient-to-b from-[hsla(202,28%,77%,1)] to-[hsla(198,26%,93%,1)] mt-[-1px] pb-0 "
        }
      >
        <div className="max-w-6xl mx-auto md:pl-8 xl:pl-0 md:py-16">
          <h3 className="p-8 mb-4 text-2xl font-semibold text-blue-800 md:font-bold md:text-4xl md:mb-8 md:pl-0 ">
            Industry-driven solutions for modern operations
          </h3>
          <div className="pb-8 pl-8 md:pl-0">
            <MobileView>
              <LazyLoad>
                <IndustryAccordion />
              </LazyLoad>
            </MobileView>

            <BrowserView>
              <LazyLoad>
                <IndustryTab />
              </LazyLoad>
            </BrowserView>

            <div className="flex flex-col justify-between pr-8 mt-8 md:mt-16 md:flex-row md:items-center md:pr-0 ">
              <div className="text-sm leading-6 text-blue-900 md:text-base md:w-2/3">
                <p>
                  <span className="font-medium">
                    KATSANA Fleet Management and Industrial Sensors solutions{" "}
                  </span>
                  are developed to meet the exact needs of the industry. Each
                  solution is the result of deep customer understanding through
                  extensive research to identify problem statement and
                  operational needs prior to solution roll-out.
                </p>
                <p className="mt-4">
                  We take pride in our industry-focused solutions thanks to your
                  input, our customers.
                </p>
              </div>
              <div className="mt-8 mr-8 md:mt-0">
                <a
                  href="https://apps.katsana.com/get-quotation/"
                  className="inline-flex px-5 py-3 text-sm font-semibold text-white rounded-full md:px-6 md:text-base bg-primary"
                >
                  Request Consultation
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Industry Insights and Updates */}
      <div className="relative p-8 pr-0 overflow-hidden md:p-0 md:pt-32 md:pb-48">
        <StaticImage
          alt="katsana"
          src="../../static/images/photos/industrialiot.jpg"
          objectFit="cover"
          className="!absolute inset-0"
        />

        <div className="absolute top-0 left-0 w-full">
          <div
            className={
              Styles.tiltr +
              " after:bg-[hsla(198,26%,93%,1)] z-10 m-[-1px] after:h-[4vh] md:after:h-[10vh] "
            }
          ></div>
        </div>

        <div className="max-w-6xl py-8 mx-auto md:pl-8">
          <h1 className="relative z-10 mt-8 mb-8 text-xl font-semibold text-white md:mt-0 md:text-4xl">
            Latest Industry Insights
            <br /> and Updates
          </h1>
          <div className="my-16">
            <LazyLoad>
              <InsightSlider jsondata={insight} />
            </LazyLoad>
          </div>
        </div>
      </div>
      <Cta />
    </Layout>
  );
};

export default IndexPage;
