import * as React from "react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import { Link } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import "../styles/tab.css";

const SolutionsTab = () => {
  return (
    <Tabs selectedTabClassName="whitetab">
      <TabList>
        <Tab>
          <span className="text-sm md:text-base text-bluegray">
            Fleet Management Solutions
          </span>
        </Tab>
        <Tab>
          <span className="text-sm md:text-base text-bluegray">
            Industrial Sensor & Operation Solutions
          </span>
        </Tab>
        <Tab>
          <span className="text-sm md:text-base text-bluegray">
            Vehicle and Asset Tracking
          </span>
        </Tab>
      </TabList>

      <TabPanel>
        <div className="flex justify-between max-w-6xl mx-auto">
          <div className="">
            <p className="mt-8 text-sm text-white md:text-lg md:mt-16">
              Overview of all your vehicles in Real-Time
            </p>
            <p className="mt-4 text-sm leading-5 md:leading-6 md:text-sm text-bluegray">
              KATSANA® Fleet boasts the best suite of solutions engineered to help small businesses and enterprises improve
              accountability of drivers, reduce operational costs and gain real-time visibility of fleet operations.
            </p>
            <div className="grid grid-cols-2 gap-4 mt-6 md:mt-12 place-items-start">
              <div className="cols-span-1">
                <div className="flex items-center">
                  <div className="flex items-center justify-center w-10 h-10 bg-blue-600 rounded-md flex-0">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="currentColor"
                      className="h-6 text-blue-200"
                      viewBox="0 0 510 510"
                    >
                      <g>
                        <path d="m75 350h-45v-30h30v-30h-30v-30h45v-30h-75v150h75z" />
                        <path d="m510 260v-30h-75v150h30v-60h30v-30h-30v-30z" />
                        <path d="m180.409 277.177c-4.314 41.129 25.638 78.1 66.768 82.414 2.648.278 5.272.413 7.878.413 37.87 0 70.5-28.7 74.536-67.181 4.313-41.129-25.639-78.1-66.768-82.414-24.965-2.624-48.38 7.396-63.907 24.863l-161.412-93.26-15.008 25.977 161.382 93.243c-1.696 5.077-2.888 10.405-3.469 15.945zm74.558-37.18c1.562 0 3.14.082 4.728.248 24.677 2.589 42.648 24.771 40.061 49.449s-24.774 42.646-49.449 40.061c-24.678-2.589-42.649-24.771-40.061-49.449.125-1.197.308-2.374.524-3.538l36.727 21.22 15.008-25.977-36.706-21.208c7.932-6.77 18.178-10.806 29.168-10.806z" />
                        <path d="m240 0h30v60h-30z" />
                        <path
                          d="m176.59 28.348h30v40.001h-30z"
                          transform="matrix(.966 -.259 .259 .966 -5.985 51.233)"
                        />
                        <path
                          d="m117.5 52.823h30v40.001h-30z"
                          transform="matrix(.866 -.5 .5 .866 -18.66 76.005)"
                        />
                        <path
                          d="m66.759 91.759h30v40h-30z"
                          transform="matrix(.707 -.707 .707 .707 -55.079 90.546)"
                        />
                        <path
                          d="m298.41 33.348h40.001v30h-40.001z"
                          transform="matrix(.259 -.966 .966 .259 189.31 343.4)"
                        />
                        <path
                          d="m357.5 57.824h40.001v30h-40.001z"
                          transform="matrix(.5 -.866 .866 .5 125.681 363.335)"
                        />
                        <path
                          d="m408.241 96.759h40v30h-40z"
                          transform="matrix(.707 -.707 .707 .707 46.403 335.545)"
                        />
                        <path
                          d="m447.176 147.5h40.001v30h-40.001z"
                          transform="matrix(.866 -.5 .5 .866 -18.661 255.356)"
                        />
                        <path d="m268.935 480h-27.869c-64.391 0-124.928-25.075-170.459-70.606l-21.213 21.213c25.298 25.299 54.886 45.068 87.94 58.76s67.954 20.633 103.732 20.633h27.869c35.777 0 70.678-6.942 103.731-20.634 33.055-13.691 62.643-33.461 87.94-58.76l-21.213-21.213c-45.531 45.532-106.068 70.607-170.458 70.607z" />
                      </g>
                    </svg>
                  </div>
                  <p className="flex-1 ml-4 text-sm text-bluegray">
                    Fuel Monitoring System
                  </p>
                </div>
              </div>
              <div className="cols-span-1">
                <div className="flex items-center">
                  <div className="flex items-center justify-center w-10 h-10 bg-blue-600 rounded-md flex-0">
                    <svg
                      fill="currentColor"
                      className="h-6 text-blue-200"
                      viewBox="0 0 512 512"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g>
                        <path d="m437.066 74.981c-5.857-5.858-15.355-5.858-21.213 0s-5.858 15.355 0 21.213c42.656 42.655 66.147 99.409 66.147 159.806s-23.491 117.151-66.147 159.806c-5.858 5.857-5.858 15.355 0 21.213 5.868 5.868 15.365 5.848 21.213 0 48.322-48.321 74.934-112.608 74.934-181.019s-26.612-132.698-74.934-181.019z" />
                        <path d="m391.646 120.4c-5.858-5.857-15.356-5.857-21.213 0-5.857 5.858-5.857 15.355 0 21.213 30.533 30.531 47.349 71.155 47.349 114.387s-16.815 83.855-47.349 114.387c-5.858 5.858-5.858 15.355 0 21.213 5.856 5.856 15.353 5.859 21.213 0 36.2-36.198 56.136-84.355 56.136-135.601s-19.937-99.402-56.136-135.599z" />
                        <path d="m346.193 165.852c-5.858-5.857-15.356-5.857-21.213 0-5.858 5.858-5.857 15.356 0 21.213 18.4 18.398 28.533 42.88 28.533 68.934s-10.133 50.536-28.533 68.934c-5.858 5.857-5.858 15.355 0 21.213 5.857 5.857 15.354 5.859 21.213 0 24.066-24.065 37.32-56.08 37.32-90.148s-13.254-66.081-37.32-90.146z" />
                        <path d="m30 256c0-60.397 23.491-117.151 66.147-159.806 5.858-5.857 5.858-15.355 0-21.213-5.857-5.857-15.354-5.858-21.213 0-48.322 48.321-74.934 112.608-74.934 181.019s26.612 132.698 74.934 181.019c5.856 5.857 15.355 5.859 21.213 0 5.858-5.858 5.858-15.355 0-21.213-42.656-42.655-66.147-99.409-66.147-159.806z" />
                        <path d="m94.219 256c0-43.232 16.815-83.855 47.349-114.387 5.858-5.858 5.858-15.355 0-21.213-5.857-5.858-15.355-5.857-21.213 0-36.2 36.197-56.136 84.355-56.136 135.6s19.936 99.403 56.136 135.601c5.866 5.865 15.363 5.85 21.213 0 5.857-5.858 5.857-15.355 0-21.213-30.534-30.532-47.349-71.156-47.349-114.388z" />
                        <path d="m187.021 165.853c-5.857-5.858-15.355-5.858-21.213 0-24.066 24.065-37.32 56.08-37.32 90.148s13.254 66.083 37.32 90.148c5.866 5.865 15.363 5.85 21.213 0 5.858-5.858 5.857-15.356 0-21.213-18.4-18.398-28.533-42.88-28.533-68.934s10.133-50.536 28.533-68.934c5.857-5.859 5.857-15.357 0-21.215z" />
                        <path d="m256 192.8c-34.849 0-63.2 28.352-63.2 63.2s28.352 63.2 63.2 63.2 63.2-28.352 63.2-63.2-28.351-63.2-63.2-63.2zm0 96.4c-18.307 0-33.2-14.894-33.2-33.2s14.894-33.2 33.2-33.2 33.2 14.894 33.2 33.2-14.893 33.2-33.2 33.2z" />
                      </g>
                    </svg>
                  </div>
                  <p className="flex-1 ml-4 text-sm text-bluegray">
                    Live Vehicle Tracking
                  </p>
                </div>
              </div>
              <div className="cols-span-1">
                <div className="flex items-center">
                  <div className="flex items-center justify-center w-10 h-10 bg-blue-600 rounded-md flex-0">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="currentColor"
                      className="h-6 text-blue-200"
                      viewBox="0 0 512 512"
                    >
                      <g>
                        <path d="m291 309.813v-234.813c0-41.355-33.645-75-75-75s-75 33.645-75 75v234.813c-25.518 21.885-40 53.248-40 87.188 0 63.411 51.589 115 115 115s115-51.589 115-115c0-33.94-14.482-65.304-40-87.188zm-75 172.187c-46.869 0-85-38.131-85-85 0-26.987 12.396-51.776 34.009-68.011 3.771-2.833 5.991-7.276 5.991-11.993v-241.996c0-24.813 20.187-45 45-45s45 20.187 45 45v241.996c0 4.717 2.22 9.16 5.991 11.993 21.613 16.235 34.009 41.024 34.009 68.011 0 46.869-38.131 85-85 85z" />
                        <path d="m231 344.096v-269.096c0-8.284-6.716-15-15-15s-15 6.716-15 15v269.096c-23.057 6.547-40 27.777-40 52.904 0 30.327 24.673 55 55 55s55-24.673 55-55c0-25.127-16.943-46.356-40-52.904zm-15 77.904c-13.785 0-25-11.215-25-25s11.215-25 25-25 25 11.215 25 25-11.215 25-25 25z" />
                        <circle cx="366.063" cy="256" r="15" />
                        <path d="m419.097 202.967c-5.857-5.858-15.355-5.857-21.213 0-5.858 5.858-5.858 15.355 0 21.213 17.545 17.545 17.545 46.094 0 63.64-5.858 5.858-5.858 15.355 0 21.213 2.929 2.929 6.768 4.393 10.606 4.393s7.678-1.464 10.606-4.394c29.243-29.241 29.243-76.823.001-106.065z" />
                        <path d="m461.522 160.541c-5.857-5.858-15.355-5.858-21.213 0s-5.858 15.355 0 21.213c19.832 19.832 30.754 46.2 30.754 74.247s-10.922 54.415-30.754 74.247c-5.858 5.858-5.858 15.355 0 21.213 2.929 2.929 6.768 4.394 10.606 4.394s7.678-1.464 10.606-4.394c52.638-52.638 52.638-138.284.001-190.92z" />
                        <path d="m76 287h-50c-8.284 0-15 6.716-15 15s6.716 15 15 15h50c8.284 0 15-6.716 15-15s-6.716-15-15-15z" />
                        <path d="m26 257h30c8.284 0 15-6.716 15-15s-6.716-15-15-15h-30c-8.284 0-15 6.716-15 15s6.716 15 15 15z" />
                        <path d="m76 167h-50c-8.284 0-15 6.716-15 15s6.716 15 15 15h50c8.284 0 15-6.716 15-15s-6.716-15-15-15z" />
                        <path d="m26 137h30c8.284 0 15-6.716 15-15s-6.716-15-15-15h-30c-8.284 0-15 6.716-15 15s6.716 15 15 15z" />
                        <path d="m76 47h-50c-8.284 0-15 6.716-15 15s6.716 15 15 15h50c8.284 0 15-6.716 15-15s-6.716-15-15-15z" />
                      </g>
                    </svg>
                  </div>
                  <p className="flex-1 ml-4 text-sm text-bluegray">
                    Temperature Monitoring
                  </p>
                </div>
              </div>
              <div className="cols-span-1">
                <div className="flex items-center">
                  <div className="flex items-center justify-center w-10 h-10 bg-blue-600 rounded-md flex-0">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="currentColor"
                      className="h-6 text-blue-200"
                      viewBox="0 0 512.07 512.07"
                    >
                      <g>
                        <path d="m109.342 109.792c31.21-47.989 85.304-79.792 146.693-79.792s115.483 31.803 146.693 79.792l25.166-16.311c-36.564-56.222-99.939-93.481-171.859-93.481-71.921 0-135.295 37.258-171.859 93.481z" />
                        <path d="m168.064 147.851c18.716-28.779 51.156-47.851 87.971-47.851s69.255 19.072 87.972 47.851l25.166-16.311c-24.071-37.012-65.792-61.54-113.138-61.54s-89.066 24.528-113.137 61.54z" />
                        <path d="m477.901 206.214c-9.188-4.979-19.703-7.81-30.867-7.81h-95v30h95c19.299 0 35 15.701 35 35v183.666c0 19.299-15.701 35-35 35h-381.999c-19.299 0-35-15.701-35-35v-183.667c0-19.299 15.701-35 35-35h95v-30h-95c-11.164 0-21.678 2.831-30.867 7.81-20.309 11.005-34.133 32.513-34.133 57.19v183.667c0 35.841 29.159 65 65 65h381.999c35.841 0 65-29.159 65-65v-183.667c.001-24.677-13.823-46.184-34.133-57.189z" />
                        <path d="m107.699 378.356h12.101l17.02 52.906h31.514l-19.738-61.354c11.196-7.936 18.48-20.717 18.48-35.112 0-24.019-20.266-43.559-45.176-43.559h-44.201v140.025h30zm0-57.119h14.202c8.227 0 15.176 6.21 15.176 13.559 0 7.35-6.949 13.56-15.176 13.56h-14.202z" />
                        <path d="m194.628 431.237h30v-56.235h25.968v-30h-25.968v-23.765h39.692v-30h-69.692z" />
                        <path d="m289.226 291.237h30v140h-30z" />
                        <path d="m383.97 430.993c33.751-.589 58.248-29.926 58.248-69.755 0-41.869-23.88-70-59.422-70h-37.648v140.09c0-.001 29.954-.181 38.822-.335zm-8.822-109.756h7.648c27.3 0 29.422 30.616 29.422 40 0 27.119-14.45 39.51-28.772 39.76-2.452.043-5.37.08-8.299.112v-79.872z" />
                        <g>
                          <path d="m256.035 140.013c-33.245 0-60.195 26.95-60.195 60.195s26.95 60.195 60.195 60.195 60.195-26.95 60.195-60.195c0-33.244-26.95-60.195-60.195-60.195zm0 90.39c-16.653 0-30.201-13.548-30.201-30.202 0-16.653 13.548-30.202 30.201-30.202s30.201 13.548 30.201 30.202-13.548 30.202-30.201 30.202z" />
                        </g>
                      </g>
                    </svg>
                  </div>
                  <p className="flex-1 ml-4 text-sm text-bluegray">
                    Driver identification via RFID, NRIC, iButton
                  </p>
                </div>
              </div>
              <div className="cols-span-1">
                <div className="flex items-center">
                  <div className="flex items-center justify-center w-10 h-10 bg-blue-600 rounded-md flex-0">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="currentColor"
                      className="h-6 text-blue-200"
                      viewBox="0 0 512.005 512.005"
                    >
                      <g>
                        <path d="m385.574 232.968h-88.553c8.411-19.027 19.163-48.882 24.145-85.58 3.966-29.2-18.672-55.179-48.055-55.179-23.534 0-43.634 16.91-47.793 40.209-9.051 50.696-41.169 102.522-52.806 120.048h-4.829v-.385c0-8.284-6.716-15-15-15h-76.26c-8.284 0-15 6.716-15 15v244.923c0 8.284 6.716 15 15 15h76.26c8.284 0 15-6.716 15-15v-13.004h205.616c33.487 0 61.288-25.124 64.667-58.44l12.277-121.031c3.877-38.209-26.145-71.561-64.669-71.561zm-294.15 249.037v-214.923h46.26c-.038 254.398 0 199.522 0 214.923zm328.972-180.503-12.277 121.031c-1.818 17.939-16.788 31.468-34.819 31.468h-205.617v-171.534h12.74c4.814 0 9.336-2.311 12.156-6.212 2.035-2.815 49.985-69.742 62.271-138.564 1.602-8.97 9.28-15.48 18.26-15.48 11.234 0 19.854 9.904 18.328 21.144-7.74 57.019-31.321 96.425-31.545 96.795-6.072 9.938 1.041 22.82 12.8 22.82h112.88c20.589-.002 36.934 17.726 34.823 38.532z" />
                        <path d="m312.712 71.314c7.63 3.149 16.417-.461 19.587-8.146l15.512-37.6c3.159-7.658-.487-16.428-8.146-19.587-7.66-3.16-16.428.488-19.587 8.146l-15.512 37.6c-3.159 7.658.487 16.428 8.146 19.587z" />
                        <path d="m217.88 60.057c2.503 7.902 10.939 12.273 18.829 9.771 7.897-2.502 12.272-10.932 9.771-18.829l-12.836-40.523c-2.501-7.898-10.934-12.273-18.829-9.771-7.897 2.502-12.272 10.932-9.771 18.829z" />
                        <path d="m120.398 97.631 51.443 32.678c7.005 4.451 16.271 2.36 20.704-4.618 4.442-6.993 2.374-16.263-4.618-20.704l-51.443-32.678c-6.994-4.445-16.264-2.374-20.704 4.618-4.442 6.993-2.374 16.262 4.618 20.704z" />
                        <path d="m374.445 137.233 51.121-26.705c7.343-3.836 10.186-12.897 6.35-20.24-3.836-7.342-12.897-10.189-20.24-6.35l-51.121 26.705c-7.343 3.836-10.186 12.897-6.35 20.24 3.848 7.369 12.924 10.174 20.24 6.35z" />
                      </g>
                    </svg>
                  </div>
                  <p className="flex-1 ml-4 text-sm text-bluegray">
                    Driver Behaviour Score via Drivemark
                  </p>
                </div>
              </div>
              <div className="cols-span-1">
                <div className="flex items-center">
                  <div className="flex items-center justify-center w-10 h-10 bg-blue-600 rounded-md flex-0">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 512 512"
                      fill="currentColor"
                      className="h-6 text-blue-200"
                    >
                      <g>
                        <g>
                          <path
                            d="M398.271,33.465h-35.965C358.925,14.471,342.302,0,322.352,0H189.648c-19.951,0-36.574,14.471-39.954,33.465h-35.965
			c-24.813,0-45,20.187-45,45V467c0,24.813,20.187,45,45,45h284.541c24.813,0,45-20.187,45-45V78.465
			C443.271,53.652,423.085,33.465,398.271,33.465z M179.055,40.594C179.055,34.752,183.807,30,189.648,30h132.703
			c5.842,0,10.594,4.752,10.594,10.594V71.97c0,5.841-4.752,10.594-10.594,10.594H189.648c-5.842,0-10.594-4.752-10.594-10.594
			V40.594z M398.271,482H113.729c-8.271,0-15-6.729-15-15V78.465c0-8.271,6.729-15,15-15h35.325v8.504
			c0,22.383,18.21,40.594,40.594,40.594h132.703c22.384,0,40.594-18.21,40.594-40.594v-8.504h35.325c8.271,0,15,6.729,15,15V467
			h0.001C413.271,475.271,406.542,482,398.271,482z"
                          />
                        </g>
                      </g>
                      <g>
                        <g>
                          <path
                            d="M362.841,194.666h-101.6c-8.284,0-15,6.716-15,15s6.716,15,15,15h101.6c8.284,0,15-6.716,15-15
			S371.125,194.666,362.841,194.666z"
                          />
                        </g>
                      </g>
                      <g>
                        <g>
                          <path
                            d="M224.544,143.078c-6.815-4.712-16.158-3.007-20.869,3.807l-33.528,48.49l-7.066-12.072
			c-4.186-7.15-13.374-9.552-20.523-5.367s-9.552,13.374-5.367,20.523l18.961,32.389c2.59,4.423,7.259,7.218,12.381,7.411
			c0.189,0.007,0.377,0.011,0.566,0.011c4.911,0,9.528-2.409,12.336-6.469l46.917-67.854
			C233.063,157.133,231.357,147.79,224.544,143.078z"
                          />
                        </g>
                      </g>
                      <g>
                        <g>
                          <path
                            d="M362.841,295.487h-101.6c-8.284,0-15,6.716-15,15s6.716,15,15,15h101.6c8.284,0,15-6.716,15-15
			S371.125,295.487,362.841,295.487z"
                          />
                        </g>
                      </g>
                      <g>
                        <g>
                          <path
                            d="M224.544,243.899c-6.815-4.713-16.158-3.007-20.869,3.807l-33.528,48.49l-7.066-12.072
			c-4.186-7.149-13.374-9.553-20.523-5.367c-7.149,4.185-9.552,13.374-5.367,20.523l18.961,32.389
			c2.59,4.423,7.259,7.218,12.381,7.411c0.189,0.007,0.377,0.011,0.566,0.011c4.911,0,9.528-2.409,12.336-6.469l46.917-67.854
			C233.063,257.954,231.357,248.611,224.544,243.899z"
                          />
                        </g>
                      </g>
                      <g>
                        <g>
                          <path
                            d="M362.841,244.702h-101.6c-8.284,0-15,6.716-15,15s6.716,15,15,15h101.6c8.284,0,15-6.716,15-15
			S371.125,244.702,362.841,244.702z"
                          />
                        </g>
                      </g>
                    </svg>
                  </div>
                  <p className="flex-1 ml-4 text-sm text-bluegray">
                    Operational Reports
                  </p>
                </div>
              </div>

              <div className="cols-span-1">
                <div className="flex items-center">
                  <div className="flex items-center justify-center w-10 h-10 bg-blue-600 rounded-md flex-0">
                    <svg
                      viewBox="0 0 512 512"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="currentColor"
                      className="h-6 text-blue-200"
                    >
                      <path d="m287 422c-19.554688 0-36.226562 12.542969-42.421875 30h-97.15625c-6.195313-17.457031-22.867187-30-42.421875-30-24.8125 0-45 20.1875-45 45s20.1875 45 45 45c19.554688 0 36.226562-12.542969 42.421875-30h97.160156c6.191407 17.457031 22.863281 30 42.417969 30 24.8125 0 45-20.1875 45-45 0-6.9375-1.578125-13.515625-4.398438-19.390625l60.007813-60.007813c5.875 2.816407 12.453125 4.398438 19.390625 4.398438 24.8125 0 45-20.1875 45-45s-20.1875-45-45-45-45 20.1875-45 45c0 6.9375 1.582031 13.515625 4.398438 19.390625l-60.007813 60.007813c-5.875-2.816407-12.453125-4.398438-19.390625-4.398438zm-182 60c-8.269531 0-15-6.730469-15-15s6.730469-15 15-15 15 6.730469 15 15-6.730469 15-15 15zm182 0c-8.269531 0-15-6.730469-15-15s6.730469-15 15-15 15 6.730469 15 15-6.730469 15-15 15zm120-150c8.269531 0 15 6.730469 15 15s-6.730469 15-15 15-15-6.730469-15-15 6.730469-15 15-15zm0 0" />
                      <path d="m105 121c-57.898438 0-105 47.101562-105 105 0 20.753906 6.019531 41.429688 16.945312 58.214844l75.484376 115.96875c2.765624 4.25 7.496093 6.816406 12.570312 6.816406s9.804688-2.566406 12.570312-6.816406l75.484376-115.96875c10.925781-16.785156 16.945312-37.460938 16.945312-58.214844 0-57.898438-47.101562-105-105-105zm62.910156 146.847656-62.910156 96.65625-62.910156-96.65625c-7.796875-11.972656-12.089844-26.835937-12.089844-41.847656 0-41.355469 33.644531-75 75-75s75 33.644531 75 75c0 15.011719-4.292969 29.875-12.089844 41.847656zm0 0" />
                      <path d="m105 181c-24.8125 0-45 20.1875-45 45s20.1875 45 45 45 45-20.1875 45-45-20.1875-45-45-45zm0 60c-8.269531 0-15-6.730469-15-15s6.730469-15 15-15 15 6.730469 15 15-6.730469 15-15 15zm0 0" />
                      <path d="m407 0c-57.898438 0-105 47.550781-105 106 0 20.386719 5.851562 40.160156 16.925781 57.183594l75.46875 116.949218c2.765625 4.28125 7.511719 6.867188 12.605469 6.867188s9.839844-2.585938 12.605469-6.867188l75.449219-116.917968c11.085937-17.027344 16.945312-36.8125 16.945312-57.214844 0-58.449219-47.101562-106-105-106zm62.878906 146.898438-62.878906 97.4375s-62.902344-97.472657-62.914062-97.488282c-7.90625-12.148437-12.085938-26.273437-12.085938-40.847656 0-41.90625 33.644531-76 75-76s75 34.09375 75 76c0 14.574219-4.179688 28.699219-12.121094 40.898438zm0 0" />
                      <path d="m407 61c-24.8125 0-45 20.1875-45 45s20.1875 45 45 45 45-20.1875 45-45-20.1875-45-45-45zm0 60c-8.269531 0-15-6.730469-15-15s6.730469-15 15-15 15 6.730469 15 15-6.730469 15-15 15zm0 0" />
                    </svg>
                  </div>
                  <p className="flex-1 ml-4 text-sm text-bluegray">
                    Digital Driver Logbook
                  </p>
                </div>
              </div>

              <div className="cols-span-1">
                <div className="flex items-center">
                  <div className="flex items-center justify-center w-10 h-10 bg-blue-600 rounded-md flex-0">
                    <svg
                      viewBox="0 0 510 510"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="currentColor"
                      className="h-6 text-blue-200"
                    >
                      <g>
                        <path d="m180 300h30v30h-30z" />
                        <path d="m300 300h30v30h-30z" />
                        <path d="m60 205h30v30h-30z" />
                        <path d="m500.982 178.431-25.15-25.625-12.901-72.628c-3.106-17.487-18.25-30.178-36.01-30.178h-103.842c-17.76 0-32.904 12.691-36.01 30.178l-11.514 64.822h-41.109l-11.515-64.823c-3.107-17.486-18.251-30.177-36.01-30.177h-103.842c-17.759 0-32.903 12.691-36.01 30.178l-12.901 72.628-25.151 25.626c-5.815 5.924-9.017 13.759-9.017 22.06v130.443c0 18.784 15.281 34.065 34.064 34.065h21.871c18.784 0 34.065-15.281 34.065-34.064v-25.936h30v120.936c0 18.783 15.281 34.064 34.064 34.064h21.871c18.784 0 34.065-15.281 34.065-34.064v-25.936h90v25.936c0 18.783 15.281 34.064 34.064 34.064h21.871c18.784 0 34.065-15.281 34.065-34.064v-120.936h30v25.936c0 18.783 15.281 34.064 34.064 34.064h21.871c18.784 0 34.065-15.281 34.065-34.064v-130.444c0-8.299-3.201-16.133-9.018-22.061zm-21.413 21.011c.277.282.431.655.431 1.05v50.237l-48.541 24.271h-48.937l-26.69-27.194-12.933-72.806h112.68zm-168.11 170.558h-112.918l-48.541-24.271v-50.849l24.421-24.88h161.159l24.42 24.881v50.849zm.971-195 11.546 65h-137.952l11.546-65zm10.649-95h103.842c3.192 0 5.914 2.281 6.473 5.424l10.582 59.576h-137.952l10.583-59.576c.558-3.143 3.28-5.424 6.472-5.424zm-240 0h103.842c3.192 0 5.914 2.281 6.472 5.424l10.583 59.576h-137.952l10.582-59.575c.559-3.144 3.281-5.425 6.473-5.425zm-53.079 120.492c0-.395.152-.767.428-1.048l23.993-24.444h112.68l-12.933 72.806-26.69 27.194h-48.937l-48.541-24.271zm25.936 134.508h-21.872c-2.241 0-4.064-1.823-4.064-4.064v-46.665l30 15v31.665c0 2.241-1.823 4.064-4.064 4.064zm120 95h-21.871c-2.241 0-4.064-1.823-4.064-4.064v-46.665l30 15v31.665c-.001 2.241-1.824 4.064-4.065 4.064zm184.064-4.064c0 2.241-1.823 4.064-4.064 4.064h-21.871c-2.241 0-4.064-1.823-4.064-4.064v-31.665l30-15v46.665zm120-95c0 2.241-1.823 4.064-4.064 4.064h-21.871c-2.241 0-4.064-1.823-4.064-4.064v-31.665l30-15v46.665z" />
                        <path d="m420 205h30v30h-30z" />
                      </g>
                    </svg>
                  </div>
                  <p className="flex-1 ml-4 text-sm text-bluegray">
                    Fleet Maintenance Log
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="relative pl-20">
            <div className="relative w-full pl-8 mt-16 mb-8">
              <StaticImage
                alt="Aerodyne Global Maps"
                src="../images/aerodynemap.png"
                objectFit="contain"
              />
              {/* Australia */}
              <div className="mapsignals right-[30%] top-[79%]">
                <span className="signal s1"></span>
                <span className="signal s2"></span>
              </div>
              {/* Malaysia */}
              <div className="mapsignals right-[33%] top-[59%]">
                <span className="signal s1"></span>
                <span className="signal s2"></span>
              </div>
              {/* India */}
              <div className="mapsignals right-[40%] top-[48%]">
                <span className="signal s1"></span>
                <span className="signal s2"></span>
              </div>
              {/* Tiongkok */}
              <div className="mapsignals right-[28%] top-[36%]">
                <span className="signal s1"></span>
                <span className="signal s2"></span>
              </div>
              {/* UEA 1 */}
              <div className="mapsignals right-[48%] top-[46%]">
                <span className="signal s1"></span>
                <span className="signal s2"></span>
              </div>
              {/* UEA 2 */}
              <div className="mapsignals right-[49%] top-[35%]">
                <span className="signal s1"></span>
                <span className="signal s2"></span>
              </div>
              {/* EUROPE 1 */}
              <div className="mapsignals right-[59%] top-[25%]">
                <span className="signal s1"></span>
                <span className="signal s2"></span>
              </div>
              {/* EUROPE 2 */}
              <div className="mapsignals right-[56%] top-[18%]">
                <span className="signal s1"></span>
                <span className="signal s2"></span>
              </div>
              {/* SOUTH AMERICAN 1 */}
              <div className="mapsignals right-[77%] top-[81.5%]">
                <span className="signal s1"></span>
                <span className="signal s2"></span>
              </div>
              {/* SOUTH AMERICAN 2 */}
              <div className="mapsignals right-[75%] top-[61%]">
                <span className="signal s1"></span>
                <span className="signal s2"></span>
              </div>
              {/* US 1 */}
              <div className="mapsignals right-[87%] top-[40%]">
                <span className="signal s1"></span>
                <span className="signal s2"></span>
              </div>
              {/* US 2 */}
              <div className="mapsignals right-[89%] top-[29%]">
                <span className="signal s1"></span>
                <span className="signal s2"></span>
              </div>
            </div>

            <div className="relative flex">
              <StaticImage
                alt="Kamarul Muhamed"
                src="../images/kamarul.png"
                className="!absolute flex rounded-full -left-2 top-3 ring-2 ring-primary w-[85px]"
                objectFit="contain"
              />
              <p className="flex-shrink px-4 py-6 pl-16 ml-8 text-sm leading-5 text-white rounded-lg bg-primary">
                Aerodyne operates in over 35 countries. Our ground operation
                relies on KATSANA Fleet to coordinate and ensure smooth delivery
                of services to our Fortune 500 clients.
              </p>
            </div>

            <div className="flex items-center my-4 ml-4">
              <div className="flex-grow ml-4 text-gray-50">
                <h5 className="text-base font-medium leading-none">
                  Kamarul Muhamed, Group CEO Aerodyne Group
                </h5>
                <p className="mt-2 text-xs leading-none text-bluegray">
                  The largest Drone Solutions Provider in the world
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="relative z-20 flex justify-between mt-8">
          <Link
            to="/fleet-management-malaysia/"
            className="inline-flex text-sm font-semibold text-primary"
          >
            Explore Fleet Management <i className="inline-flex chevronr"></i>
          </Link>
          <Link
            to="/success-stories/"
            className="inline-flex text-sm text-gray-200 "
          >
            More customer stories <i className="inline-flex chevronr"></i>
          </Link>
        </div>
      </TabPanel>
      <TabPanel>
        <div className="flex items-center justify-between max-w-6xl mx-auto">
          <div className="w-1/2">
            <h5 className="mt-4 text-sm text-white md:text-lg md:mt-8">
              Real-time Operational Visibility & Domain Awareness
            </h5>
            <p className="mt-4 text-sm leading-5 md:leading-6 md:text-sm text-bluegray">
              Connect existing or new sensors to the KATSANA® Orbital Platform to
              get real-time feed and insights from remote or inaccessible
              facilities. Automated alerts provides instant notification of
              anomalies such as equipment failures, intrusions, safety
              compliance and many more.
            </p>
            <div className="grid grid-cols-2 gap-4 mt-6 md:mt-12 place-items-start">
              <div className="col-span-2">
                <div className="flex items-center">
                  <div className="flex items-center justify-center w-10 h-10 bg-blue-600 rounded-md flex-0">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 512 512"
                      fill="currentColor"
                      className="h-6 text-blue-200"
                    >
                      <g>
                        <g>
                          <path
                            d="M256,90c-91.533,0-166,74.468-166,166s74.467,166,166,166s166-74.468,166-166S347.533,90,256,90z M256,210
			c-8.271,0-15-6.729-15-15s6.729-15,15-15s15,6.729,15,15S264.271,210,256,210z M330.86,369.484v-77.106l31.14-30V226h-30v23.622
			l-31.14,30v104.766c-9.475,3.32-19.425,5.619-29.72,6.764v-153.78C288.526,231.141,301,214.503,301,195c0-24.813-20.187-45-45-45
			s-45,20.187-45,45c0,19.607,12.609,36.315,30.14,42.467v153.718c-10.394-1.135-20.438-3.446-30-6.797V279.622l-31.14-30V226h-30
			v36.378l31.14,30v77.106C144.33,345.12,120,303.351,120,256c0-74.99,61.009-136,136-136s136,61.01,136,136
			C392,303.351,367.67,345.12,330.86,369.484z"
                          />
                        </g>
                      </g>
                      <g>
                        <g>
                          <path
                            d="M475.701,202.384c-5.465-22.441-14.29-43.722-26.33-63.496l21.481-30.073l-67.468-67.469l-30.057,21.469
			c-19.743-12.032-40.991-20.857-63.401-26.334L303.847,0h-95.414l-6.08,36.48c-22.364,5.465-43.581,14.271-63.294,26.271
			l-30.245-21.604l-67.468,67.468l21.591,30.227c-11.992,19.685-20.798,40.867-26.275,63.201L0,208.152v95.414l36.591,6.099
			c5.446,22.338,14.226,43.534,26.191,63.232l-21.634,30.288l67.468,67.469l30.184-21.56c19.698,12.025,40.902,20.858,63.262,26.354
			L208.153,512h95.414l6.068-36.41c22.413-5.445,43.674-14.243,63.434-26.25l30.117,21.513l67.468-67.467l-21.438-30.014
			c12.066-19.757,20.918-41.026,26.415-63.464l36.369-6.06v-95.414L475.701,202.384z M482,278.434l-31.543,5.257l-2.004,10.194
			c-5.053,25.697-15.072,49.767-29.78,71.538l-5.833,8.635l18.631,26.085l-31.527,31.526l-26.147-18.677l-8.627,5.799
			c-21.786,14.643-45.85,24.6-71.525,29.595l-10.22,1.987L278.153,482h-44.586l-5.285-31.707l-10.184-2.011
			c-25.626-5.061-49.633-15.063-71.354-29.731l-8.635-5.832l-26.251,18.752l-31.527-31.527l18.796-26.314l-5.796-8.627
			c-14.602-21.733-24.543-45.736-29.546-71.342l-1.995-10.209l-31.79-5.3v-44.586l31.83-5.304l2.006-10.191
			c5.04-25.601,15.014-49.591,29.646-71.304l5.817-8.632l-18.77-26.278l31.527-31.526l26.289,18.777l8.63-5.811
			c21.742-14.639,45.762-24.608,71.392-29.632l10.202-1.999L233.847,30h44.586l5.278,31.666l10.202,2
			c25.672,5.032,49.724,15.023,71.487,29.694l8.632,5.819l26.11-18.65l31.527,31.527l-18.658,26.12l5.813,8.63
			c14.679,21.795,24.665,45.877,29.68,71.578l1.993,10.212L482,233.848V278.434z"
                          />
                        </g>
                      </g>
                    </svg>
                  </div>
                  <p className="flex-1 ml-4 text-sm text-bluegray">
                    Overal Equipment Effectiveness & Health
                  </p>
                </div>
              </div>

              <div className="col-span-2">
                <div className="flex items-center">
                  <div className="flex items-center justify-center w-10 h-10 bg-blue-600 rounded-md flex-0">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 511.216 511.216"
                      fill="currentColor"
                      className="h-6 text-blue-200"
                    >
                      <g id="XMLID_10_">
                        <path
                          id="XMLID_658_"
                          d="M473.318,0c-20.663,0-37.474,16.811-37.474,37.474v24.148h-33.278c-8.284,0-15,6.716-15,15v28.284h-14.685   c-8.284,0-15,6.716-15,15s6.716,15,15,15h14.685v28.284c0,8.284,6.716,15,15,15h33.278v154.836h-33.278c-8.284,0-15,6.716-15,15   v28.284h-14.5c-8.284,0-15,6.716-15,15s6.716,15,15,15h14.5v28.284c0,8.284,6.716,15,15,15h33.278v46.623c0,8.284,6.716,15,15,15   h44.948c8.284,0,15-6.716,15-15V37.474C510.792,16.811,493.982,0,473.318,0z M417.566,148.19V91.623h18.278v56.567H417.566z    M417.566,419.593v-56.567h18.278v56.567H417.566z M480.792,481.216h-14.948V37.474c0-4.121,3.353-7.474,7.474-7.474   s7.475,3.353,7.475,7.474V481.216z"
                        />
                        <path
                          id="XMLID_661_"
                          d="M184.949,134.906h46.983c8.284,0,15-6.716,15-15s-6.716-15-15-15h-46.983c-8.284,0-15,6.716-15,15   S176.665,134.906,184.949,134.906z"
                        />
                        <path
                          id="XMLID_662_"
                          d="M278.916,134.906h46.983c8.284,0,15-6.716,15-15s-6.716-15-15-15h-46.983c-8.284,0-15,6.716-15,15   S270.631,134.906,278.916,134.906z"
                        />
                        <path
                          id="XMLID_665_"
                          d="M138.15,376.31h-14.5v-28.284c0-8.284-6.716-15-15-15H75.372V178.19h33.278c8.284,0,15-6.716,15-15   v-28.284h14.315c8.284,0,15-6.716,15-15s-6.716-15-15-15H123.65V76.623c0-8.284-6.716-15-15-15H75.372V37.474   C75.372,16.811,58.561,0,37.898,0C17.234,0,0.423,16.811,0.423,37.474v458.742c0,8.284,6.716,15,15,15h44.948   c8.284,0,15-6.716,15-15v-46.623h33.278c8.284,0,15-6.716,15-15V406.31h14.5c8.284,0,15-6.716,15-15S146.434,376.31,138.15,376.31z    M93.65,91.623v56.567H75.372V91.623H93.65z M45.372,481.216H30.423V37.474c0-4.121,3.354-7.474,7.475-7.474   s7.474,3.353,7.474,7.474V481.216z M93.65,419.593H75.372v-56.567H93.65V419.593z"
                        />
                        <path
                          id="XMLID_668_"
                          d="M326.083,376.31H279.1c-8.284,0-15,6.716-15,15s6.716,15,15,15h46.983c8.284,0,15-6.716,15-15   S334.368,376.31,326.083,376.31z"
                        />
                        <path
                          id="XMLID_670_"
                          d="M232.117,376.31h-46.983c-8.284,0-15,6.716-15,15s6.716,15,15,15h46.983c8.284,0,15-6.716,15-15   S240.401,376.31,232.117,376.31z"
                        />
                      </g>
                    </svg>
                  </div>
                  <p className="flex-1 ml-4 text-sm text-bluegray">
                    Volumetric & Environmetal Sensors
                  </p>
                </div>
              </div>

              <div className="col-span-1">
                <div className="flex items-center">
                  <div className="flex items-center justify-center w-10 h-10 bg-blue-600 rounded-md flex-0">
                    <svg
                      viewBox="0 0 512.00078 512"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="currentColor"
                      className="h-6 text-blue-200"
                    >
                      <path d="m118 0c-65.066406 0-118 52.933594-118 118 0 29.875 11.167969 57.1875 29.539062 78h-9.539062c-11.046875 0-20 8.953125-20 20v276c0 11.046875 8.953125 20 20 20h196c11.046875 0 20-8.953125 20-20v-276c0-11.046875-8.953125-20-20-20h-9.539062c18.371093-20.808594 29.539062-48.125 29.539062-78 0-65.066406-52.933594-118-118-118zm78 472h-156v-236h156zm-78-276c-43.007812 0-78-34.992188-78-78s34.992188-78 78-78 78 34.992188 78 78-34.992188 78-78 78zm279.660156 242.339844c7.808594 7.8125 7.808594 20.476562 0 28.285156-7.8125 7.8125-20.476562 7.808594-28.285156 0-56.34375-56.34375-87.375-131.144531-87.375-210.625s31.03125-154.28125 87.375-210.625c7.8125-7.8125 20.476562-7.8125 28.285156 0 7.808594 7.808594 7.808594 20.472656 0 28.285156-48.789062 48.789063-75.660156 113.546875-75.660156 182.339844 0 68.796875 26.871094 133.550781 75.660156 182.339844zm56.539063-56.539063c7.8125 7.808594 7.8125 20.472657 0 28.28125-7.808594 7.8125-20.472657 7.8125-28.28125 0-41.21875-41.214843-63.917969-95.9375-63.917969-154.082031s22.699219-112.867188 63.917969-154.082031c7.808593-7.8125 20.472656-7.8125 28.28125 0 7.8125 7.808593 7.8125 20.472656 0 28.28125-33.660157 33.664062-52.199219 78.339843-52.199219 125.800781s18.539062 92.136719 52.199219 125.800781zm52.714843-60.601562c7.359376 8.238281 6.648438 20.878906-1.589843 28.238281-8.230469 7.351562-20.875 6.652344-28.238281-1.59375-22.625-25.328125-35.085938-57.945312-35.085938-91.84375s12.460938-66.515625 35.085938-91.84375c7.355468-8.238281 20-8.949219 28.238281-1.59375 8.238281 7.359375 8.949219 20.003906 1.589843 28.242188-16.066406 17.984374-24.914062 41.140624-24.914062 65.195312s8.847656 47.210938 24.914062 65.199219zm0 0" />
                    </svg>
                  </div>
                  <p className="flex-1 ml-4 text-sm text-bluegray">
                    Port Access Control with Driver Identification
                  </p>
                </div>
              </div>

              <div className="col-span-1">
                <div className="flex items-center">
                  <div className="flex items-center justify-center w-10 h-10 bg-blue-600 rounded-md flex-0">
                    <svg
                      viewBox="0 0 512 512"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="currentColor"
                      className="h-6 text-blue-200"
                    >
                      <g>
                        <path d="m198.512 83.844 21.213 21.214c9.71-9.71 22.593-15.058 36.276-15.058s26.565 5.347 36.275 15.057l21.213-21.214c-15.377-15.376-35.793-23.843-57.488-23.843-21.696 0-42.112 8.468-57.489 23.844z" />
                        <path d="m256.001 30c29.683 0 57.626 11.595 78.681 32.649l21.213-21.214c-26.722-26.72-62.199-41.435-99.894-41.435-37.697 0-73.174 14.716-99.896 41.438l21.213 21.213c21.056-21.055 48.999-32.651 78.683-32.651z" />
                        <path d="m361.342 391.66c67.2-4.047 120.634-59.993 120.634-128.192 0-70.82-57.616-128.436-128.436-128.436h-195.08c-70.819 0-128.436 57.616-128.436 128.436 0 68.2 53.434 124.145 120.634 128.192v30.292h-90.61v90.048h30v-60.048h90.635v60.048h30v-60.048h90.635v60.048h30v-60.048h90.635v60.048h30v-90.048h-90.61v-30.292zm90.634-128.192c0 54.277-44.158 98.436-98.436 98.436h-52.516v-196.872h52.516c54.277 0 98.436 44.158 98.436 98.436zm-211-22.812h30.049v45.624h-30.049zm30.048-30h-30.049v-45.624h30.049zm-30.048 105.624h30.049v45.624h-30.049zm-180.952-52.812c0-54.277 44.158-98.436 98.436-98.436h52.516v196.872h-52.516c-54.277 0-98.436-44.158-98.436-98.436zm120.634 128.436h150.684v30.048h-150.684z" />
                      </g>
                    </svg>
                  </div>
                  <p className="flex-1 ml-4 text-sm text-bluegray">
                    Skid Tank Monitoring System
                  </p>
                </div>
              </div>

              <div className="col-span-1">
                <div className="flex items-center">
                  <div className="flex items-center justify-center w-10 h-10 bg-blue-600 rounded-md flex-0">
                    <svg
                      viewBox="0 0 512.459 512.459"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="currentColor"
                      className="h-6 text-blue-200"
                    >
                      <g>
                        <path d="m480.879 245.22h-6.68l-167.802-72.113c.8-3.608 1.234-7.351 1.234-11.196 0-23.383-15.602-43.19-36.963-49.628v-22.164h93.188c8.284 0 15-6.716 15-15v-60.119c0-8.284-6.716-15-15-15h-217.408c-8.284 0-15 6.716-15 15v60.119c0 8.284 6.716 15 15 15h94.22v22.164c-21.361 6.438-36.962 26.246-36.962 49.628 0 3.886.445 7.668 1.261 11.312l-166.627 71.997h-6.759c-17.174 0-31.146 12.616-31.146 28.123v210.993c0 15.507 13.973 28.124 31.146 28.124h465.444c8.284 0 15-6.716 15-15v-224.117c0-15.507-13.973-28.123-31.146-28.123zm-319.431-215.22h187.407v30.119h-92.404c-.261-.013-.519-.04-.783-.04s-.522.026-.783.04h-93.437zm94.22 110.072c12.11 0 21.963 9.797 21.963 21.839s-9.853 21.84-21.963 21.84c-12.109 0-21.962-9.797-21.962-21.84s9.852-21.839 21.962-21.839zm-35.775 59.382c9.325 8.848 21.92 14.297 35.775 14.297 13.898 0 26.529-5.482 35.863-14.38l106.687 45.849h-284.244zm262.132 75.904v207.101c-1.101-.138-450.48.265-451.591-.138v-206.963z" />
                        <path d="m91.69 305.08c-8.284 0-15 6.716-15 15v117.188c0 8.284 6.716 15 15 15s15-6.716 15-15v-117.188c0-8.284-6.716-15-15-15z" />
                        <path d="m173.96 304.673c-8.284 0-15 6.716-15 15v117.188c0 8.284 6.716 15 15 15s15-6.716 15-15v-117.188c0-8.284-6.716-15-15-15z" />
                        <path d="m256.229 302.215c-8.284 0-15 6.716-15 15v117.188c0 8.284 6.716 15 15 15s15-6.716 15-15v-117.188c0-8.284-6.716-15-15-15z" />
                        <path d="m338.5 303.854c-8.284 0-15 6.716-15 15v117.188c0 8.284 6.716 15 15 15s15-6.716 15-15v-117.188c0-8.284-6.716-15-15-15z" />
                        <path d="m420.769 302.912c-8.284 0-15 6.716-15 15v117.188c0 8.284 6.716 15 15 15s15-6.716 15-15v-117.188c0-8.284-6.716-15-15-15z" />
                      </g>
                    </svg>
                  </div>
                  <p className="flex-1 ml-4 text-sm text-bluegray">
                    Container Identification & Movement Management
                  </p>
                </div>
              </div>

              <div className="col-span-1">
                <div className="flex items-center">
                  <div className="flex items-center justify-center w-10 h-10 bg-blue-600 rounded-md flex-0">
                    <svg
                      viewBox="0 0 512 512"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="currentColor"
                      className="h-6 text-blue-200"
                    >
                      <g>
                        <path d="m459.669 82.906-196-81.377c-4.91-2.038-10.429-2.039-15.338 0l-196 81.377c-7.465 3.1-12.331 10.388-12.331 18.471v98.925c0 136.213 82.329 258.74 208.442 310.215 4.844 1.977 10.271 1.977 15.116 0 126.111-51.474 208.442-174.001 208.442-310.215v-98.925c0-8.083-4.865-15.371-12.331-18.471zm-27.669 117.396c0 115.795-68 222.392-176 269.974-105.114-46.311-176-151.041-176-269.974v-85.573l176-73.074 176 73.074zm-198.106 67.414 85.964-85.963c7.81-7.81 20.473-7.811 28.284 0s7.81 20.474-.001 28.284l-100.105 100.105c-7.812 7.812-20.475 7.809-28.284 0l-55.894-55.894c-7.811-7.811-7.811-20.474 0-28.284s20.474-7.811 28.284 0z" />
                      </g>
                    </svg>
                  </div>
                  <p className="flex-1 ml-4 text-sm text-bluegray">
                    Fuel Access and Authorisation
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="relative pl-20">
            <StaticImage
              alt="Kamarul Muhamed"
              src="../images/iot.png"
              className="w-full"
              objectFit="contain"
            />
          </div>
        </div>
        <div className="relative z-20 flex justify-between mt-8">
          <Link
            to="/industrial-iot/"
            className="inline-flex text-sm font-semibold text-primary"
          >
            Explore Industrial Sensor & Operation Solutions
            <i className="inline-flex chevronr"></i>
          </Link>
          <Link
            to="/success-stories/"
            className="inline-flex text-sm text-gray-200 "
          >
            More customer stories <i className="inline-flex chevronr"></i>
          </Link>
        </div>
      </TabPanel>
      <TabPanel>
        <div className="flex items-center justify-between max-w-6xl mx-auto">
          <div className="">
            <h5 className="mt-4 text-sm text-white md:text-lg md:mt-8">
              Real-time Vehicle & Asset Location Tracking
            </h5>
            <p className="mt-4 text-sm leading-5 md:leading-6 md:text-sm text-bluegray">
              KATSANA is engineered to modernize vehicle and asset location
              tracking. The robust KATSANA Platform tracks more than 120,000
              vehicles daily across South East Asia, with new features are added
              continuously based on your feedbacks, our customers.
            </p>
            <div className="grid grid-cols-1 gap-4 mt-6 md:mt-12 place-items-start">
              <div className="col-span-1">
                <div className="flex items-center">
                  <div className="flex items-center justify-center w-10 h-10 bg-blue-600 rounded-md flex-0">
                    <svg
                      viewBox="0 0 512 512"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="currentcolor"
                      className="h-6 text-blue-200"
                    >
                      <g>
                        <path d="m512 347.394-46.496-21.459-28.172-73.949h-105.344v-32.399l1.796-1.796c20.777-20.775 32.22-48.4 32.22-77.784 0-60.656-49.348-110.004-110.004-110.004s-110.004 49.347-110.004 110.003c0 14.639 2.841 28.84 8.26 41.976h-154.256v270.015h62.584c6.192 17.46 22.866 30.002 42.422 30.002s36.229-12.542 42.421-30.002h217.145c6.192 17.46 22.866 30.002 42.422 30.002s36.23-12.542 42.422-30.002h62.584zm-80.102-25.404h-39.906v-40.005h24.666zm-175.898-261.988c44.114 0 80.004 35.89 80.004 80.004 0 21.37-8.322 41.461-23.433 56.571l-56.571 56.572-56.572-56.572c-15.11-15.11-23.432-35.2-23.432-56.571 0-44.114 35.89-80.004 80.004-80.004zm-226 151.979h142.806c1.726 1.989 3.528 3.928 5.409 5.809l77.785 77.785 45.988-45.989v172.41h-154.561c-6.192-17.46-22.866-30.002-42.421-30.002s-36.23 12.542-42.422 30.002h-32.584zm75.006 240.017c-8.272 0-15.002-6.729-15.002-15.002s6.73-15.002 15.002-15.002 15.001 6.729 15.001 15.002-6.729 15.002-15.001 15.002zm301.988 0c-8.272 0-15.002-6.729-15.002-15.002s6.729-15.002 15.002-15.002 15.002 6.729 15.002 15.002-6.729 15.002-15.002 15.002zm75.006-30.002h-32.584c-6.192-17.46-22.866-30.002-42.422-30.002s-36.23 12.542-42.422 30.002h-32.584v-140.011h30.004v70.005h88.378l31.63 14.599z" />
                        <path d="m256 190.01c27.571 0 50.002-22.431 50.002-50.002s-22.431-50.002-50.002-50.002-50.002 22.431-50.002 50.002 22.431 50.002 50.002 50.002zm0-70.004c11.029 0 20.002 8.973 20.002 20.002s-8.973 20.002-20.002 20.002-20.002-8.973-20.002-20.002 8.973-20.002 20.002-20.002z" />
                      </g>
                    </svg>
                  </div>
                  <p className="flex-1 ml-4 text-sm text-bluegray">
                    Real-time tracking with the fastest update in the industry
                  </p>
                </div>
              </div>

              <div className="col-span-1">
                <div className="flex items-center">
                  <div className="flex items-center justify-center w-10 h-10 bg-blue-600 rounded-md flex-0">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 512 512"
                      fill="currentColor"
                      className="h-6 text-blue-200"
                    >
                      <g>
                        <g>
                          <path
                            d="M457,331.723c-25.127,0-46.356,16.943-52.904,40H351v-58.555h54.213c58.883,0,106.787-47.904,106.787-106.787
			c0-47.211-31.312-88.685-75.764-102.193c-13.437-53.804-62.005-92.393-118.254-92.393c-21.805,0-42.693,5.623-61.127,16.366
			c-19.523-17.854-45.122-27.884-72.019-27.884c-57.693,0-104.847,45.991-106.729,103.233C32.446,116.273,0,158.08,0,206.381
			c0,58.883,47.904,106.787,106.787,106.787H161v58.555h-53.096c-6.547-23.057-27.777-40-52.904-40c-30.327,0-55,24.673-55,55
			c0,30.327,24.673,55,55,55c25.127,0,46.356-16.943,52.904-40H176c8.284,0,15-6.716,15-15v-73.555h50v90.651
			c-23.057,6.547-40,27.777-40,52.904c0,30.327,24.673,55,55,55c30.327,0,55-24.673,55-55c0-25.127-16.943-46.356-40-52.904v-90.651
			h50v73.555c0,8.284,6.716,15,15,15h68.096c6.547,23.057,27.777,40,52.904,40c30.327,0,55-24.673,55-55
			C512,356.396,487.327,331.723,457,331.723z M55,411.723c-13.785,0-25-11.215-25-25c0-13.785,11.215-25,25-25
			c13.785,0,25,11.215,25,25C80,400.508,68.785,411.723,55,411.723z M281,456.723c0,13.785-11.215,25-25,25
			c-13.785,0-25-11.215-25-25c0-13.785,11.215-25,25-25C269.785,431.723,281,442.938,281,456.723z M106.787,283.168
			c-42.34,0-76.787-34.446-76.787-76.787c0-37.843,28.207-70.506,65.61-75.976c7.893-1.154,13.501-8.284,12.765-16.226
			c-0.217-2.343-0.327-4.737-0.327-7.116c0-42.339,34.447-76.785,76.787-76.785c22.59,0,43.893,9.82,58.445,26.942
			c5.109,6.011,14,7.037,20.345,2.349c15.732-11.628,34.528-17.774,54.356-17.774c45.628,0,83.573,33.479,90.666,77.03
			c1.003,6.158,5.714,11.052,11.829,12.29c35.649,7.217,61.523,38.87,61.523,75.266c0,42.341-34.447,76.787-76.787,76.787H106.787z
			 M457,411.723c-13.785,0-25-11.215-25-25c0-13.785,11.215-25,25-25c13.785,0,25,11.215,25,25
			C482,400.508,470.785,411.723,457,411.723z"
                          />
                        </g>
                      </g>
                    </svg>
                  </div>
                  <p className="flex-1 ml-4 text-sm text-bluegray">
                    Longest historical data storage in the industry
                  </p>
                </div>
              </div>

              <div className="col-span-1">
                <div className="flex items-center">
                  <div className="flex items-center justify-center w-10 h-10 bg-blue-600 rounded-md flex-0">
                    <svg
                      viewBox="0 0 512 512"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="currentColor"
                      className="h-6 text-blue-200"
                    >
                      <g>
                        <path d="m436 421h-30v-151c0-82.71-67.29-150-150-150s-150 67.29-150 150v151h-30c-8.284 0-15 6.716-15 15v61c0 8.284 6.716 15 15 15h360c8.284 0 15-6.716 15-15v-61c0-8.284-6.716-15-15-15zm-300-151c0-66.168 53.832-120 120-120s120 53.832 120 120v151h-240zm285 212h-330v-31h330z" />
                        <path d="m255 90c8.284 0 15-6.716 15-15v-60c0-8.284-6.716-15-15-15s-15 6.716-15 15v60c0 8.284 6.716 15 15 15z" />
                        <path d="m136.894 111.202c4.155 7.197 13.336 9.62 20.49 5.49 7.175-4.142 9.633-13.316 5.49-20.49l-30-51.961c-4.143-7.175-13.316-9.632-20.49-5.49-7.175 4.142-9.633 13.316-5.49 20.49z" />
                        <path d="m86.192 188.616c4.143-7.174 1.685-16.348-5.49-20.49l-51.962-30c-7.175-4.143-16.349-1.685-20.49 5.49-4.143 7.174-1.685 16.348 5.49 20.49l51.962 30c7.143 4.125 16.33 1.716 20.49-5.49z" />
                        <path d="m354.616 116.692c7.143 4.125 16.33 1.715 20.49-5.49l30-51.961c4.143-7.174 1.685-16.348-5.49-20.49-7.174-4.144-16.349-1.685-20.49 5.49l-30 51.961c-4.143 7.174-1.685 16.348 5.49 20.49z" />
                        <path d="m503.75 143.616c-4.143-7.175-13.315-9.631-20.49-5.49l-51.962 30c-7.175 4.142-9.633 13.316-5.49 20.49 4.155 7.197 13.336 9.62 20.49 5.49l51.962-30c7.175-4.142 9.633-13.315 5.49-20.49z" />
                      </g>
                    </svg>
                  </div>
                  <p className="flex-1 ml-4 text-sm text-bluegray">
                    Real-time, immediate alert to your phone at no cost
                  </p>
                </div>
              </div>

              <div className="col-span-1">
                <div className="flex items-center">
                  <div className="flex items-center justify-center w-10 h-10 bg-blue-600 rounded-md flex-0">
                    <svg
                      viewBox="0 0 512 512"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="currentColor"
                      className="h-6 text-blue-200"
                    >
                      <path d="m173.5 121h-60c-8.284 0-15 6.716-15 15v60c0 8.284 6.716 15 15 15h60c8.284 0 15-6.716 15-15v-60c0-8.284-6.716-15-15-15zm-15 60h-30v-30h30z" />
                      <path d="m406.413 291.814c-8.002-12.501-22.001-20.812-37.913-20.814v-226c0-24.813-20.187-45-45-45h-240c-24.813 0-45 20.187-45 45v391c0 24.813 20.187 45 45 45 112.596 0 105.363.077 107.072-.16l15.235 24.161c2.748 4.356 7.538 6.999 12.688 6.999h210.005c5.069 0 9.795-2.56 12.564-6.806l30-46c1.589-2.437 2.436-5.284 2.436-8.194v-119.998c0-34.378-37.223-56.084-67.087-39.188zm-127.913-114.315c-7.967-7.143-18.483-11.498-30-11.499v-15h30zm14.995 33.501c8.308 0 15.005-6.732 15.005-15v-60c0-8.284-6.716-15-15-15h-60c-8.284 0-15 6.716-15 15v41.491c-9.202 8.245-15.005 20.21-15.005 33.509v120.816l-3.596-5.737c-13.059-20.839-40.338-27.426-61.472-14.829-21.856 13.029-28.663 41.442-15.095 62.959l10.589 16.791h-65.421v-300h270v176.499c-12.324-11.049-29.555-14.399-45.005-8.919 0-4.425 0-43.144 0-47.58zm-209.995-181h240c8.271 0 15 6.729 15 15v16h-270v-16c0-8.271 6.729-15 15-15zm0 421c-8.271 0-15-6.729-15-15v-15h84.338l18.917 30zm360-4.459-23.125 35.459h-193.605l-78.06-123.792c-4.568-7.242-2.274-16.805 5.079-21.189 7.048-4.203 16.332-1.964 20.69 4.991l31.305 49.956c3.553 5.667 10.432 8.3 16.854 6.451 6.429-1.848 10.856-7.728 10.856-14.416v-173.001c0-8.271 6.729-15 15-15s15 6.729 15 15v166c0 8.284 6.716 15 15 15s15-6.716 15-15v-76c0-8.271 6.729-15 15-15s15 6.729 15 15v15 61c0 8.284 6.716 15 15 15s15-6.716 15-15v-61c0-8.271 6.729-15 15-15s15 6.729 15 15v15.002 45.998c0 8.284 6.716 15 15 15s15-6.716 15-15v-45.998c0-8.272 6.729-15.002 15.002-15.002s15.003 6.73 15.003 15.002v115.539z" />
                    </svg>
                  </div>
                  <p className="flex-1 ml-4 text-sm text-bluegray">
                    Modern apps for IOS and Android
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="relative pl-20">
            <div className="testimonial1 md:mt-8">
              <div className="relative flex">
                <StaticImage
                  alt="Kamarul Muhamed"
                  src="../images/kudrat.png"
                  className="!absolute flex rounded-full -left-2 top-3 ring-2 ring-primary w-[85px]"
                  objectFit="contain"
                />
                <p className="flex-shrink px-4 py-6 pl-16 ml-8 text-sm leading-5 text-white rounded-lg bg-primary">
                  KATSANA® has been a great addition to our fleet of vehicles.
                  Apart from enabling us to identify risky drivers, we have been
                  able to recover over 40 missing vehicles from hire-purchase
                  debtors; all with 100% recovery rate of missing vehicles.
                </p>
              </div>
              <div className="flex items-center my-4 ml-4">
                <div className="flex-grow ml-4 text-gray-50">
                  <h5 className="text-base font-medium leading-none">
                    Cheong Mun Yuen, Fleet Manager
                  </h5>
                  <p className="mt-2 text-xs leading-none text-bluegray">
                    Kudrat Group of Companies
                  </p>
                </div>
              </div>
            </div>
            <div className="mt-8 testimonial2">
              <div className="relative flex">
                <StaticImage
                  alt="Kamarul Muhamed"
                  src="../images/wcm.png"
                  className="!absolute flex rounded-full -left-2 top-3 ring-2 ring-primary w-[85px]"
                  objectFit="contain"
                />
                <p className="flex-shrink px-4 py-6 pl-16 ml-8 text-sm leading-5 text-white rounded-lg bg-primary">
                  KATSANA® allowed WCM Machinery to recover a stolen lorry
                  together with a multi-million dollar electric generator in
                  under an hour. The police also recover another truck that was
                  also stolen & nabbed the thieves in the vicinity.
                </p>
              </div>
              <div className="flex items-center my-4 ml-4">
                <div className="flex-grow ml-4 text-gray-50">
                  <h5 className="text-base font-medium leading-none">
                    Ms Siah, Asset Manager
                  </h5>
                  <p className="mt-2 text-xs leading-none text-bluegray">
                    WCM Machinery Sdn Bhd
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="relative z-20 flex justify-between mt-8">
          <Link
            to="/fleet-management-malaysia/"
            className="inline-flex text-sm font-semibold text-primary"
          >
            Explore Vehicle and Asset Location Tracking
            <i className="inline-flex chevronr"></i>
          </Link>
          <Link
            to="/success-stories/"
            className="inline-flex text-sm text-gray-200 "
          >
            More customer stories <i className="inline-flex chevronr"></i>
          </Link>
        </div>
      </TabPanel>
    </Tabs>
  );
};

export default SolutionsTab;
